<template>
  <div class="stepBar" >
    <ul class="gavc-stepper">
      <li v-for="step in totalSteps" :key="step" class="gavc-step "
          v-bind:class="{ 'gavc-step--is-actif': step === currentStep }">
         <div class="gavc-tooltip">
             <div class="gavc-tooltip-icon"> {{step}} </div>
         </div>
         <div class="gavc-step-text">
           {{labels[step - 1]}}
         </div>
      </li>
    </ul>
  </div>
</template>

<script>


export default {
  name: 'stepBar',
  props: {
    labels: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      default: 1
    },
    totalSteps: {
      type: Number,
      required: true
    }
  },
  methods: {
    stepClass (step) {
      var divClass = "col d-none d-xl-block"
      if (step === 1) {
        divClass = divClass + ' first-register-step'
      }
      if (step === this.currentStep) {
        divClass = divClass.replace('d-none d-xl-block', '') + ' active-register-step'
      }
      if (step === this.totalSteps) {
        divClass = divClass + ' last-register-step'
      }
      return divClass
    }
  }
}
</script>
